
import Vue from 'vue';
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { __ } from '@/libraries/lang';

declare const ns;

export default Vue.extend({
    name: 'ns-date-range-picker',
    data: () => {
        return {
            dateRange: {
                startDate: null,
                endDate: null,
            }
        }
    },
    components: {
        DateRangePicker
    },
    mounted() {
        if ( this.field.value !== undefined ) {
            this.dateRange    =   this.field.value;
        }
    },
    watch: {
        dateRange() {
            this.field.value    =   this.dateRange;
            this.$emit( 'change', this );
        }
    },
    methods: {
        __,
        getFormattedDate( date ) {
            return date !== null ? moment( date ).format( 'YYYY-MM-DD HH:mm' ) : __( 'N/D' );
        },
        clearDate() {
            this.dateRange      =   {
                startDate: null,
                endDate: null
            };
            this.field.value    =   undefined;
        }
    },
    computed: {
        hasError() {
            if ( this.field.errors !== undefined && this.field.errors.length > 0 ) {
                return true;
            }
            return false;
        },
        disabledClass() {
            return this.field.disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-transparent';
        },
        inputClass() {
            return this.disabledClass + ' ' + this.leadClass
        },
        leadClass() {
            return this.leading ? 'pl-8' : 'px-4';
        }
    },
    props: [ 'placeholder', 'leading', 'type', 'field' ],
})
