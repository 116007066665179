







































































































import { nsSnackBar } from '@/bootstrap';
import nsPosConfirmPopupVue from '@/popups/ns-pos-confirm-popup.vue';
import { Popup } from '@/libraries/popup';
import { __ } from '@/libraries/lang';
import { HttpStatusResponse } from '@/interfaces/http-status-response';
import { HttpCrudResponse } from '@/interfaces/http-crud-response';
import nsOrdersFilterPopupVue from '@/popups/ns-orders-filter-popup.vue';

declare const nsCrudHandler;

export default {
    data: () => {
        return {
            isRefreshing: false,
            sortColumn: '',
            searchInput: '',
            queryFiltersString: '',
            searchQuery: '',
            page: 1,
            bulkAction: '',
            bulkActions: [],
            queryFilters:[],
            withFilters: false,
            columns: [],
            selectedEntries:[],
            globallyChecked: false,
            result: {
                current_page: null,
                data: [],
                first_page_url: null,
                from: null,
                last_page: null, 
                last_page_url: null,
                next_page_url: null,
                path: null,
                per_page: null,
                prev_page_url: null,
                to: null,
                total: null,
            }
        }
    }, 
    mounted() {
        if ( this.identifier !== undefined  ) {
            nsCrudHandler.defineInstance( this.identifier, this );
        }

        this.loadConfig();
    },
    props: [ 'src', 'create-url', 'mode', 'identifier', 'queryParams' ],
    computed: {
        /**
         * helps to get parsed
         * src link. Useful to enable sort
         * pagination, total items per pages
         */
        getParsedSrc() {
            return `${this.src}?${this.sortColumn}${this.searchQuery}${this.queryFiltersString}${this.queryPage}${this.getQueryParams() ? '&' + this.getQueryParams() : ''}`
        },

        showQueryFilters() {
            return this.queryFilters.length > 0;
        },

        getSelectedAction() {
            const action    =   this.bulkActions.filter( action => {
                return action.identifier === this.bulkAction;
            });
            return action.length > 0 ? action[0] : false;
        },

        pagination() {
            if ( this.result ) {
                return this.pageNumbers( this.result.last_page, this.result.current_page );
            }
            return [];
        },

        queryPage() {
            if ( this.result ) {
                return `&page=${this.page}`
            }
            return '';
        },
        resultInfo() {
            return __( 'displaying {perPage} on {items} items' )
                .replace( '{perPage}', this.result.total )
                .replace( '{items}', this.result.total )
        }
    },
    methods: {
        __,
        getQueryParams() {
            if ( this.queryParams ) {
                return ( Object.keys( this.queryParams )
                    .map(key => `${key}=${this.queryParams[key]}`)
                    .join('&') );
            }
            return '';
        },

        pageNumbers(count, current) {
            var shownPages = 3;
            var result = [];

            if ( current - 3 > 1 ) {
                result.push( 1, '...' );
            }

            for( let i = 1; i <= count; i++ ) {
                if ( current + 3 > i && current - 3 < i ) {
                    result.push(i);
                }
            }

            if ( current + 3 < count ) {
                result.push( '...', count );
            }
            
            return result.filter( f => f > 0 || typeof f === 'string' );
        },

        downloadContent() {
            nsHttpClient.post( `${this.src}/export?${this.getParsedSrc}`, { entries : this.selectedEntries.map( e => e.$id ) })
                .subscribe( (result: any) => {
                    setTimeout( () => document.location   =   result.url, 300 );
                    nsSnackBar
                        .success( __( 'The document has been generated.' ) )
                        .subscribe()
                }, error => {
                    nsSnackBar
                        .error( error.message || __( 'Unexpected error occured.' ) )
                        .subscribe();
                })
        },

        clearSelectedEntries() {
            Popup.show( nsPosConfirmPopupVue, {
                title: __( 'Clear Selected Entries ?' ),
                message: __( 'Would you like to clear all selected entries ?' ),
                onAction: ( action ) => {
                    if ( action ) {
                        this.selectedEntries    =   [];
                    }
                }
            });
        },

        /**
         * Will select a row and add it to a virtual storage
         * that will ensure that while browsing to pages, these
         * entries remains selected.
         * @param row actual row
         */
        refreshRow( row ) {
            if ( row.$checked === true ) {
                const result    =   this.selectedEntries.filter( e => e.$id === row.$id );

                if ( result.length  === 0 ) {
                    this.selectedEntries.push( row );
                }
            } else {
                const result    =   this.selectedEntries.filter( e => e.$id === row.$id );

                if ( result.length > 0 ) {
                    const index     =   this.selectedEntries.indexOf( result[0] );
                    this.selectedEntries.splice( index, 1 );
                }
            }
        },

        handleShowOptions( e ) {
            this.result.data.forEach( row => {
                if ( row.$id !== e.$id ) {
                    row.$toggled    =   false;
                }
            });
        },
        handleGlobalChange( event ) {
            this.globallyChecked    =   event;
            this.result.data.forEach( r => {
                r.$checked = event;
                this.refreshRow( r );
            });
        },
        loadConfig() {
            const request   =   nsHttpClient.get( `${this.src}/config?${this.getQueryParams()}` );
            request.subscribe( (f:any) => {
                this.columns        =   f.columns;
                this.bulkActions    =   f.bulkActions;
                this.queryFilters   =   f.queryFilters
                this.refresh();
            }, ( error ) => {
                nsSnackBar.error( error.message, 'OK', { duration: false }).subscribe();
            });
        },
        cancelSearch() {
            this.searchInput    =   '';
            this.search();
        },
        search() {
            if ( this.searchInput ) {
                this.searchQuery    =   `&search=${this.searchInput}`;
            } else {
                this.searchQuery    =   '';
            }

            this.refresh();
        },
        sort( identifier ) {

            for ( let key in this.columns ) {
                if ( key !== identifier ) {
                    this.columns[ key ].$sorted     =   false;
                    this.columns[ key ].$direction  =   '';
                }
            }

            this.columns[ identifier ].$sorted      =   true;

            switch( this.columns[ identifier ].$direction ) {
                case 'asc':
                    this.columns[ identifier ].$direction   =   'desc';
                break;
                case 'desc':
                    this.columns[ identifier ].$direction   =   '';
                break;
                case '':
                    this.columns[ identifier ].$direction   =   'asc';
                break;
            }
            
            if ( [ 'asc', 'desc' ].includes( this.columns[ identifier ].$direction ) ) {
                this.sortColumn     =   `active=${identifier}&direction=${this.columns[ identifier ].$direction}`;
            } else {
                this.sortColumn     =   '';
            }

            this.$emit( 'sort', this.columns[ identifier ] );
            this.refresh();
        },
        bulkDo() {
            if ( this.bulkAction ) {
                if ( this.selectedEntries.length > 0 ) {
                    if ( confirm( this.getSelectedAction.confirm || this.$slots[ 'error-bulk-confirmation' ] || __( 'Would you like to perform the selected bulk action on the selected entries ?' ) ) ) {
                        return nsHttpClient.post( `${this.src}/bulk-actions`, {
                            action: this.bulkAction,
                            entries: this.selectedEntries.map( r => r.$id )
                        }).subscribe({
                            next: (result: HttpStatusResponse ) => {
                                nsSnackBar.info( result.message ).subscribe();
                                this.selectedEntries    =   [];
                                this.refresh();
                            },
                            error: ( error ) => {
                                nsSnackBar.error( error.message )
                                    .subscribe();
                            }
                        })
                    }
                } else {
                    return nsSnackBar.error( this.$slots[ 'error-no-selection' ] ? this.$slots[ 'error-no-selection' ][0].text : __( 'No selection has been made.' ) )
                        .subscribe();
                }
            } else {
                return nsSnackBar.error( this.$slots[ 'error-no-action' ] ? this.$slots[ 'error-no-action' ][0].text : __( 'No action has been selected.' ) )
                    .subscribe();
            }

        },

        async openQueryFilter() {
            try {
                const result    =   await new Promise( ( resolve, reject ) => {
                    Popup.show( nsOrdersFilterPopupVue, { resolve, reject, queryFilters: this.queryFilters });
                });

                this.withFilters                =   false;
                this.queryFiltersString         =   '';

                /**
                 * in case there is a change
                 * on the query filters.
                 */
                if ( result !== null ) {
                    this.withFilters            =   true;
                    this.queryFiltersString     =   '&queryFilters=' + encodeURIComponent( JSON.stringify(result) ); 
                } 

                this.refresh();
            } catch( exception ) {
                // ...
            }
        },

        refresh() {
            this.globallyChecked    =   false;
            this.isRefreshing       =   true;
            const request           =   nsHttpClient.get( `${this.getParsedSrc}` );
            request.subscribe( (f:HttpCrudResponse) => {
                /**
                 * if the entries were already
                 * checked, we'll make sure to restore the checked status.
                 */
                f.data          =   f.data.map( entry => {
                    const selected  =   this.selectedEntries.filter( e => e.$id === entry.$id ).length > 0;
                    
                    if( selected ) {
                        entry.$checked  =   true;
                    }

                    return entry;
                });

                this.isRefreshing   =   false;
                this.result     =   f;
                this.page       =   f.current_page;
            }, ( error ) => {
                this.isRefreshing   =   false;
                nsSnackBar.error( error.message ).subscribe();
            });
        }
    },
}
