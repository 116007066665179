





















import FormValidation from '@/libraries/form-validation'
import { __ } from '@/libraries/lang';
import popupCloser from '@/libraries/popup-closer';
import popupResolver from '@/libraries/popup-resolver';
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            fields: [],
            validation: new FormValidation
        }
    },
    methods: {
        __,
        popupCloser,
        popupResolver,
        closePopup() {
            this.popupResolver( false );
        },
        useFilters() {
            this.popupResolver( this.validation.extractFields( this.fields ) );
        },
        clearFilters() {
            this.fields.forEach( field => field.value = '' );
            this.popupResolver( null );
        }
    },
    mounted() {
        this.fields     =   this.validation.createFields( this.$popupParams.queryFilters );
        this.popupCloser();
    }
})
